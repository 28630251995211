import { formatDistanceToNow } from 'date-fns';
import { useContext, useMemo, useState } from 'react';
import NumberFormat from 'react-number-format';

import { Destination } from '../../../../../../../typings/Destination.interface';
import { BackpressureIssueWithData } from '../../../../../../../typings/Issue.interface';
import { formatAmountOfTime, pluralize } from '../../../../utils';
import { Granularity } from '../../../../utils/formatCubeQuery';
import { getMsIntervalByUnit } from '../../../../utils/rules';
import Alert from '../../../common/base/Alert';
import Button from '../../../common/base/Button';
import { StyledCard, StyledCardSection } from '../../../common/base/Card';
import Skeleton from '../../../common/base/Skeleton';
import Text from '../../../common/base/Text';
import LineChart from '../../../common/Chart/LineChart';
import Dropdown from '../../../common/Dropdown';
import { Div } from '../../../common/helpers/StyledUtils';
import {
  charts_metric_time_units,
  MetricOptions,
} from '../../../common/metrics/metric-definitions';
import MetricChart from '../../../common/metrics/MetricChart';
import MetricError from '../../../common/metrics/MetricError';
import MetricWrapper from '../../../common/metrics/MetricWrapper';
import { ChartData, useMetric } from '../../../common/metrics/useMetric';
import { DashboardContext } from '../DashboardContext';
import ChangeRateLimit from './ChangeRateLimit';

const rateLimitToGranularity = (granularity: Granularity, destination: Destination): number => {
  if (destination.rate_limit_period === 'second' && granularity === 'minute') {
    return destination.rate_limit! * 60;
  }
  if (destination.rate_limit_period === 'second' && granularity === 'hour') {
    return destination.rate_limit! * 60 * 60;
  }
  if (destination.rate_limit_period === 'minute' && granularity === 'hour') {
    return destination.rate_limit! * 60;
  }
  if (destination.rate_limit_period === 'hour' && granularity === 'minute') {
    return Math.round(destination.rate_limit! / 60);
  }
  return destination.rate_limit!;
};

const BackpressureIssue: React.FC<{
  issue: BackpressureIssueWithData;
}> = ({ issue }) => {
  const { subscription, team } = useContext(DashboardContext);

  const [destination, setDestination] = useState(issue.data.destination);
  const [time_unit, setTimeUnit] = useState('1h');
  const [refresh_key, setRefreshKey] = useState(new Date().getTime());

  const [delay_value, delay_unit] = getMsIntervalByUnit(issue.aggregation_keys.delay[0]);

  const { now, last_minute_start, retention_start } = useMemo(() => {
    const now = new Date();
    const last_minute_start = new Date(now.getTime() - 1000 * 60).toISOString();
    const retention_start = new Date(
      now.getTime() - 1000 * 60 * 24 * (subscription?.retention_days ?? 0),
    ).toISOString();
    return { now, last_minute_start, retention_start };
  }, [refresh_key]);

  const { data: data_input, error: input_error } = useMetric<ChartData>(
    'chart:line',
    'events_pending_rate',
    refresh_key,
    {
      options: {
        filters: { destination_id: [destination.id] },
      } satisfies MetricOptions,
      time_unit,
      force_rate_as: 'minute',
    },
  );

  const { data: data_output, error: output_error } = useMetric<ChartData>(
    'chart:line',
    'attempts_delivered_rate',
    refresh_key,
    {
      options: {
        filters: { destination_id: [destination.id] },
      } satisfies MetricOptions,
      time_unit,
      force_rate_as: 'minute',
    },
  );

  const { data: latest_input, refetch: refetchInputTotal } = useMetric<number>(
    'card',
    'events_new_pending_count',
    refresh_key,
    {
      options: {
        filters: { destination_id: [destination.id] },
      } satisfies MetricOptions,
      date_range: {
        min: last_minute_start,
        max: now.toISOString(),
      },
    },
  );

  const { data: latest_output, refetch: refetchOutputTotal } = useMetric<number>(
    'card',
    'attempts_delivered_count',
    refresh_key,
    {
      options: {
        filters: { destination_id: [destination.id] },
      } satisfies MetricOptions,
      date_range: {
        min: last_minute_start,
        max: now.toISOString(),
      },
    },
  );

  const { data: total_count_pending, refetch: refetchTotalCountPending } = useMetric<number>(
    'card',
    'events_pending_count',
    refresh_key,
    {
      options: {
        filters: { destination_id: [destination.id] },
      } satisfies MetricOptions,
      date_range: {
        min: retention_start,
        max: now.toISOString(),
      },
    },
  );

  console.log(total_count_pending);

  const { data: oldest_attempt_date, refetch: refetchOldest } = useMetric<string | number>(
    'card',
    'attempts_oldest_pending',
    refresh_key,
    {
      options: {
        filters: { destination_id: [destination.id] },
      } satisfies MetricOptions,
      date_range: {
        min: retention_start,
        max: now.toISOString(),
      },
    },
  );

  const max_events = useMetric<ChartData>('chart:line', 'events_max_rate', 0, {
    time_unit,
  });

  let events_above_threshold = false;
  if (max_events.data && max_events.data.data) {
    const max_event = Math.max(...max_events.data.data.map((d) => d.y as number));
    if (max_event > team!.max_events_per_second) {
      events_above_threshold = true;
    }
  }

  // Handle data refresh
  const onRefreshMetrics = () => {
    refetchTotalCountPending();
    refetchOldest();
    refetchInputTotal();
    refetchOutputTotal();
  };

  const onRefreshCharts = () => {
    setRefreshKey(new Date().getTime());
  };

  const loading =
    !(typeof total_count_pending === 'number' && total_count_pending >= 0) ||
    !(typeof latest_input === 'number' && latest_input >= 0) ||
    !(typeof latest_output === 'number' && latest_output >= 0) ||
    typeof total_count_pending !== 'number';

  let rate, backpressure, time_until_resolution;
  if (!loading) {
    console.log(latest_output, latest_input, total_count_pending);
    rate = latest_output - latest_input;
    backpressure = total_count_pending === 0 ? 0 : Math.round(total_count_pending / latest_output);
    time_until_resolution =
      backpressure === 0
        ? 0
        : latest_input > latest_output
          ? Infinity
          : total_count_pending === 0
            ? 0
            : Math.round(total_count_pending / rate);
  }

  const loadingSkeleton = (
    <>
      <Skeleton variant="rectangle" h={{ px: 24 }} w={{ px: 80 }} loading />
      <Skeleton variant="rectangle" h={{ px: 16 }} w={{ px: 140 }} m={{ t: 1 }} loading />
    </>
  );

  const chart_props = {
    time_options: {
      time_unit,
      time_units: charts_metric_time_units,
      onTimeUnitSelected: setTimeUnit,
    },
    onRefresh: onRefreshCharts,
  };

  const rate_limit = destination.rate_limit || team!.max_events_per_second;
  const rate_limit_period = destination.rate_limit_period || 'seconds';

  return (
    <Div m={{ t: 18 }}>
      <Div flex={{ justify: 'space-between', align: 'center' }} m={{ b: 4 }}>
        <Div>
          <Text heading size="l" m={0} as="h2">
            Backpressure Details
          </Text>
          <Text muted m={0} as="h2">
            Last updated {formatDistanceToNow(refresh_key)} ago
          </Text>
        </Div>
        <Button onClick={onRefreshMetrics} icon="reload" outline />
      </Div>
      <Div m={{ t: 4 }}>
        <Div flex={{ gap: 4 }}>
          <StyledCard p={3}>
            <Text subtitle size="s" muted>
              Projected Backpressure
            </Text>
            {backpressure === undefined || latest_output === undefined ? (
              loadingSkeleton
            ) : (
              <>
                <Text subtitle size="l">
                  {time_until_resolution <= 0
                    ? '⃠' // ENCLOSING CIRCLE BACKSLASH unicode character
                    : backpressure === Infinity
                      ? '∞'
                      : formatAmountOfTime(backpressure)}
                </Text>
                <Text muted subtitle size="s">
                  at {latest_output} events delivered / min
                </Text>
              </>
            )}
          </StyledCard>
          <StyledCard p={3}>
            <Text subtitle size="s" muted>
              Est. Time to Resolution
            </Text>
            {time_until_resolution === undefined ? (
              loadingSkeleton
            ) : (
              <>
                <Text subtitle size="l">
                  {time_until_resolution <= 0
                    ? '⃠' // ENCLOSING CIRCLE BACKSLASH unicode character
                    : time_until_resolution === Infinity
                      ? '∞'
                      : formatAmountOfTime(time_until_resolution)}
                </Text>
                <Text muted subtitle size="s">
                  based on last minute's trend
                </Text>
              </>
            )}
          </StyledCard>
          <StyledCard p={3}>
            <Text subtitle size="s" muted>
              Pending Events
            </Text>
            {total_count_pending === undefined ? (
              loadingSkeleton
            ) : (
              <>
                <Text subtitle size="l">
                  <NumberFormat
                    renderText={(v) => v}
                    displayType="text"
                    value={total_count_pending || 0}
                    thousandSeparator={','}
                  />
                </Text>
                <Text muted subtitle size="s">
                  total events
                </Text>
              </>
            )}
          </StyledCard>
          <StyledCard p={3}>
            <Text subtitle size="s" muted>
              Highest Delay
            </Text>
            {oldest_attempt_date === undefined ? (
              loadingSkeleton
            ) : (
              <>
                <Text subtitle size="l">
                  {!oldest_attempt_date ||
                  typeof oldest_attempt_date !== 'string' ||
                  isNaN(new Date(`${oldest_attempt_date}z`).valueOf())
                    ? '⃠'
                    : formatDistanceToNow(new Date(`${oldest_attempt_date}z`))}
                </Text>
                <Text muted subtitle size="s">
                  pending duration
                </Text>
              </>
            )}
          </StyledCard>
        </Div>
        {(issue.status === 'ACKNOWLEDGED' || issue.status === 'OPENED') && (
          <Alert inline bare m={{ t: 4 }}>
            Issue will automatically resolve if the projected backpressure falls under {delay_value}{' '}
            {pluralize(delay_value, delay_unit)}
          </Alert>
        )}
      </Div>
      <Div m={{ t: 16 }}>
        <MetricWrapper
          title="Events rate over time"
          description="Historical rates of pending events and delivered events"
          {...chart_props}>
          <StyledCard m={{ b: 4 }}>
            <StyledCardSection p={3} flex={{ justify: 'space-between', align: 'center' }}>
              <Div>
                <Text subtitle muted size="s" as="p" m={{ b: 0 }}>
                  Destination max delivery rate
                </Text>
                <Text subtitle size="l" m={{ b: 0 }}>
                  {rate_limit_period === 'concurrent'
                    ? `${rate_limit} ${rate_limit_period}`
                    : `${rate_limit} / ${rate_limit_period}`}
                </Text>
              </Div>
              <Div>
                <Dropdown placement="bottom-end" label="Edit" icon="edit" outline>
                  <ChangeRateLimit
                    destination={destination}
                    subscription={subscription!}
                    onUpdate={(destination) => {
                      setDestination(destination);
                      onRefreshCharts();
                      onRefreshMetrics();
                    }}
                  />
                </Dropdown>
              </Div>
            </StyledCardSection>
          </StyledCard>
          <StyledCard>
            {!input_error || !output_error ? (
              <LineChart
                legend_placeholder={'Pending Events'}
                datasets={
                  !data_input || !data_output
                    ? null
                    : [
                        {
                          key: 'new',
                          label: 'Pending Events',
                          theme_color: 'primary' as const,
                          data: data_input.data,
                          total: data_input.total,
                          highest: data_input.highest,
                          average: data_input.average,
                          getDataLabel: (v: number) => `${Math.round(v)} / min`,
                          metric_type: 'rate' as const,
                        },
                        {
                          key: 'delivered',
                          label: 'Delivered Events',
                          theme_color: 'success' as const,
                          data: data_output.data,
                          total: data_output.total,
                          highest: data_output.highest,
                          average: data_output.average,
                          getDataLabel: (v: number) => `${Math.round(v)} / min`,
                          metric_type: 'rate' as const,
                        },
                        ...(rate_limit_period !== 'concurrent'
                          ? [
                              {
                                key: 'limit',
                                label: 'Max delivery rate',
                                theme_color: 'danger' as const,
                                dashed: true,
                                data: data_output.data.map((d) => ({
                                  ...d,
                                  y: !destination.rate_limit
                                    ? rate_limit! * 60
                                    : rateLimitToGranularity('minute', destination),
                                })),
                                // Placeholder values not actually used for anything
                                total: 0,
                                highest: 0,
                                average: 0,
                                getDataLabel: (v: number) =>
                                  `${(
                                    v / data_output.time_unit_configs.sampling.value
                                  ).toLocaleString()} / min`,
                                metric_type: 'rate' as const,
                              },
                            ]
                          : []),
                      ]
                }
              />
            ) : (
              <Div
                flex={{ direction: 'column', align: 'center', justify: 'center' }}
                h={{ px: 509 }}>
                <MetricError />
              </Div>
            )}
          </StyledCard>
        </MetricWrapper>
      </Div>
      <Div m={{ t: 16 }}>
        <MetricWrapper
          title="Project Throughput"
          description="Historical event throughput for the project"
          {...chart_props}>
          <StyledCard>
            {!input_error || !output_error ? (
              <LineChart
                loading={!max_events}
                datasets={
                  max_events && max_events.data
                    ? [
                        {
                          key: 'delivered',
                          label: 'Max new events per second',
                          theme_color: 'primary' as const,
                          monospace: false,
                          data: max_events.data.data,
                          total: max_events.data.total,
                          highest: max_events.data.highest,
                          average: max_events.data.average,
                          getDataLabel: (v: number) => `${v} / second`,
                          metric_type: 'max' as const,
                        },
                        {
                          key: 'limit',
                          label: 'Throughput limit',
                          theme_color: 'danger' as const,
                          monospace: false,
                          dashed: true,
                          data: max_events.data.data.map((d) => ({
                            ...d,
                            y: team!.max_events_per_second,
                          })),
                          // Placeholder values not actually used for anything
                          total: 0,
                          highest: 0,
                          average: 0,
                          getDataLabel: (v: number) => `${v} / second`,
                          metric_type: 'rate' as const,
                        },
                      ]
                    : []
                }
              />
            ) : (
              <Div
                flex={{ direction: 'column', align: 'center', justify: 'center' }}
                h={{ px: 509 }}>
                <MetricError />
              </Div>
            )}
          </StyledCard>
        </MetricWrapper>
      </Div>
      <Div m={{ t: 16 }}>
        <MetricWrapper
          title="Destination response time"
          description="The average time in milliseconds your server took to respond to the HTTP request"
          {...chart_props}>
          <StyledCard>
            <MetricChart
              type={'chart:line'}
              metric={'attempts_average_response_time'}
              refresh_key={refresh_key}
              configs={{
                time_unit,
              }}
            />
          </StyledCard>
        </MetricWrapper>
      </Div>
      <Div m={{ y: 16 }}>
        <MetricWrapper
          title="Error Rate"
          description="The error rate of delivery attempts"
          {...chart_props}>
          <StyledCard>
            <MetricChart
              type={'chart:line'}
              metric={'attempts_error_rate'}
              refresh_key={refresh_key}
              configs={{
                time_unit,
              }}
            />
          </StyledCard>
        </MetricWrapper>
      </Div>
    </Div>
  );
};

export default BackpressureIssue;
