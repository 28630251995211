import { Form, Formik } from 'formik';
import { useContext, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';

import Button from '../../../common/base/Button';
import { StyledCard, StyledCardSection } from '../../../common/base/Card';
import Text from '../../../common/base/Text';
import TextInput from '../../../common/Form/Fields/TextInput';
import { Div } from '../../../common/helpers/StyledUtils';
import { useToasts } from '../../../common/Toast';
import { GlobalContext } from '../../../contexts/GlobalContext';
import FullscreenLayout from '../../../layouts/FullscreenLayout';
import { DashboardContext } from '../DashboardContext';
import { useTheme } from 'styled-components';
import { UserContext } from '../../../contexts/UserContext';
import useLocalStorage from '../../../hooks/useLocalStorage';
import { Promotion, Referrer, TrackingType } from '../../../../typings/Tracking.interface';

const CreateOrganization: React.FC = () => {
  const { HookdeckAPI, setAPITeamId } = useContext(GlobalContext);
  const { user } = useContext(UserContext);
  const { organization, mutateOrganization, mutateTeam } = useContext(DashboardContext);
  const { addToast } = useToasts();
  const theme = useTheme();
  const submitRef = useRef(false);
  const history = useHistory();

  const [promotion] = useLocalStorage<Promotion | null>(TrackingType.promotion, null);
  const [referrer] = useLocalStorage<Referrer | null>(TrackingType.referrer, null);

  useEffect(() => {
    if (referrer || promotion) {
      const trackingData = {
        promotion: promotion?.slug,
        referrer: referrer?.slug,
      };
      HookdeckAPI.track.event('Signup tracked', trackingData).catch(() => null);
    }
    // Only execute on the client
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateOrcreateOrganization = (name: string) => {
    const onComplete = () => {
      HookdeckAPI.track.event('Completed Onboarding');
      return history.push('/create-first-connection');
    };
    if (organization) {
      return HookdeckAPI.organizations.update({ name }).then(() => {
        return onComplete();
      });
    }
    return HookdeckAPI.organizations.create({ name }).then(({ default_team, organization }) => {
      mutateOrganization(organization);
      mutateTeam(default_team);
      setAPITeamId(default_team.id);
      return onComplete();
    });
  };

  return (
    <FullscreenLayout center>
      <Div style={{ zIndex: 1, position: 'relative' }} w={{ px: 560 }}>
        <img
          src={`/images/cube-${theme.mode}.svg`}
          style={{
            zIndex: -1,
            left: '-100px',
            top: '-200px',
            transform: 'scale(0.5)',
            position: 'absolute',
          }}
        />
        <img
          src={`/images/cube-${theme.mode}.svg`}
          style={{
            zIndex: -1,
            right: '-200px',
            top: '100px',
            transform: 'scale(0.8) rotate(30deg)',
            position: 'absolute',
          }}
        />
        <img
          src={`/images/cube-${theme.mode}.svg`}
          style={{
            zIndex: -1,
            left: '-100px',
            bottom: '-200px',
            position: 'absolute',
            transform: 'scale(0.6) rotate(-25deg)',
          }}
        />
        <Formik
          initialValues={{
            name: organization?.name || '',
            discovery_method: '',
          }}
          validateOnMount
          validate={(v) => (!v.name || v.name.length === 0 ? { name: 'Required ' } : {})}
          onSubmit={async (values) => {
            if (submitRef.current === true) {
              return;
            }
            submitRef.current = true;

            const { name, discovery_method } = values;
            if (user) {
              HookdeckAPI.track
                .event('Submitted Onboarding', {
                  discovery_method,
                })
                .catch(() => null);
            }
            try {
              await updateOrcreateOrganization(name);
              submitRef.current = false;
            } catch {
              submitRef.current = false;
              addToast('error', 'Failed to create your organization, try again!');
            }
          }}>
          {(props) => (
            <Form>
              <Div p={{ x: 2 }}>
                <Text heading size="2xl" m={{ b: 1 }}>
                  Welcome to Hookdeck!
                </Text>
                <Text muted size="l" m={{ b: 8 }}>
                  Hookdeck allows you to receive, process, and deliver messages across your
                  event-driven architecture. To begin, create an organization below.
                </Text>
                <StyledCard m={{ b: 6 }}>
                  <StyledCardSection p={{ x: 4, y: 4 }}>
                    <TextInput
                      label="How did you hear about Hookdeck?"
                      m={{ b: 0 }}
                      min_w={{ px: 360 }}
                      name="discovery_method"
                      placeholder={`Google search for "Event Gateway", X (Twitter), LinkedIn, YouTube, etc...`}
                      help="Optional"
                    />
                  </StyledCardSection>
                </StyledCard>
                <StyledCard m={{ b: 6 }}>
                  <StyledCardSection p={4}>
                    <Text heading m={{ b: 0 }}>
                      Create an organization
                    </Text>
                    <Text muted>
                      Organizations house projects and members, and are where all plans and billing
                      occur. Typically, Hookdeck organizations represent companies or other groups.
                    </Text>
                    <TextInput
                      name="name"
                      m={{ y: 4 }}
                      required
                      placeholder="Organization name..."
                    />
                    <Button
                      block
                      disabled={props.isSubmitting || !props.isValid}
                      primary
                      submit
                      icon={props.isSubmitting ? 'loading' : 'success'}>
                      {!props.isSubmitting && (
                        <>{organization ? 'Update' : 'Create'} Organization</>
                      )}
                    </Button>
                  </StyledCardSection>
                </StyledCard>
              </Div>
            </Form>
          )}
        </Formik>
      </Div>
    </FullscreenLayout>
  );
};

export default CreateOrganization;
